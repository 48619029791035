<div class="menu" (click)="menuClicked($event)">
    <div class="main-menu">
        <ng-scrollbar class="scroll">
            <ul class="list-unstyled">
                <ng-container *ngFor="let item of menuItems">
                    <li *ngIf="item.hasRole"
                        [ngClass]="{'active': (selectedParentMenu === item.id && viewingParentMenu === '') || viewingParentMenu === item.id}">
                        <a *ngIf="item.newWindow" [href]="item.to" rel="noopener noreferrer" target="_blank">
                            <i [ngClass]="item.icon"></i>{{item.label | translate}}</a>
                        <a *ngIf="!item.newWindow && item.subs &&  item.subs.length>0 && item.to"
                            (click)="openSubMenu($event,item); false" [href]="item.to">
                            <i [ngClass]="item.icon"></i> {{item.label | translate}}</a>
                        <a *ngIf="!item.newWindow && item.subs &&  item.subs.length>0 && !item.to"
                            (click)="openSubMenu($event,item); false" class="c-pointer">
                            <i [ngClass]="item.icon"></i> {{item.label | translate}}</a>
                        <a *ngIf="!item.newWindow && (!item.subs || (item.subs &&  item.subs.length===0))"
                            (click)="changeSelectedParentHasNoSubmenu(item.id); false" [routerLink]="item.to">
                            <i [ngClass]="item.icon"></i> {{item.label | translate}}</a>


                    </li>
                </ng-container>
            </ul>
        </ng-scrollbar>
    </div>
    <div class="sub-menu">
        <ng-scrollbar class="scroll">
            <ng-container *ngFor="let item of menuItems">
                <ul class="list-unstyled" *ngIf="item.hasRole" [attr.data-parent]="item.id"
                    [ngClass]="{'d-block': (selectedParentMenu === item.id && viewingParentMenu==='') || viewingParentMenu === item.id}">
                    <ng-container *ngFor="let sub of item.subs">
                        <li *ngIf="sub.hasRole"
                            [ngClass]="{'active': currentUrl === sub.to ,'has-sub-item' : sub.subs&& sub.subs.length>0 }">
                            <a *ngIf="sub.newWindow" [href]="sub.to" rel="noopener noreferrer" target="_blank">
                                <i [ngClass]="sub.icon"></i>
                                <span class="d-inline-block">{{sub.label | translate}}</span>
                            </a>
                            <a *ngIf="!sub.newWindow && (!sub.subs || sub.subs.length<=0)" [routerLink]="[sub.to]"
                                routerLinkActive="active">
                                <i [ngClass]="sub.icon"></i>
                                <span class="d-inline-block">{{sub.label | translate}}</span>
                            </a>
                            <a (click)="collapseSub.toggle()" *ngIf="sub.subs && sub.subs.length > 0"
                                class="rotate-arrow-icon opacity-50 c-pointer">
                                <i class="simple-icon-arrow-down"></i>
                                <span class="d-inline-block">{{sub.label | translate}}</span>
                            </a>
                            <div [collapse]="false" #collapseSub="bs-collapse" [isAnimated]="true">
                                <div *ngIf="sub.subs && sub.subs.length > 0">
                                    <ul class="list-unstyled inner-level-menu">
                                        <li *ngFor="let deepSub of sub.subs" [ngClass]="{'active': currentUrl === deepSub.to}">
                                            <a *ngIf="deepSub.newWindow" [href]="deepSub.to" rel="noopener noreferrer"
                                                target="_blank">
                                                <i [ngClass]="deepSub.icon"></i>
                                                <span class="d-inline-block">{{deepSub.label | translate}}</span>
                                            </a>
                                            <a *ngIf="!deepSub.newWindow && (!deepSub.subs || deepSub.subs.length<=0)"
                                                [routerLink]="[deepSub.to]" routerLinkActive="active">
                                                <i [ngClass]="deepSub.icon"></i>
                                                <span class="d-inline-block">{{deepSub.label | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </ng-scrollbar>
    </div>
</div>