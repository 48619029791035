import { Component } from '@angular/core';
import { LangService, Language } from 'src/app/shared/lang.service';

@Component({
    selector: 'app-language-switcher',
    templateUrl: './language-switcher.component.html',
})
export class LanguageSwitcherComponent {
    isSingleLang: boolean;
    currentLanguage: string;
    languages: Language[];

    constructor(private langService: LangService) {
        this.isSingleLang = langService.isSingleLang;
        this.currentLanguage = this.langService.languageLabel;
        this.languages = this.langService.supportedLanguages;
    }

    onLanguageChange(lang) {
        this.langService.language = lang.code;
        this.currentLanguage = this.langService.languageLabel;
    }
}
