import { Injectable } from '@angular/core';
import { Auth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, updateProfile, updateCurrentUser, sendEmailVerification, isSignInWithEmailLink, signInWithEmailLink, checkActionCode, confirmPasswordReset, signInWithCustomToken } from '@angular/fire/auth';
import { from, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FunctionsService } from './functions.service';

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  initSignOut: Subject<any> = new Subject();

  constructor(private afAuth: Auth, private http: HttpClient, private fns: FunctionsService) { }

  signIn(credentials: ISignInCredentials) {
    return from(signInWithEmailAndPassword(this.afAuth, credentials.email, credentials.password));
  }

  signOut() {
    this.initSignOut.next(null);
    this.initSignOut.complete();
    return from(signOut(this.afAuth));
  }

  register(credentials: ICreateCredentials) {
    return from(
      createUserWithEmailAndPassword(this.afAuth, credentials.email, credentials.password).then(
        () => {
          updateProfile(this.afAuth.currentUser,{ displayName: credentials.displayName });
          updateCurrentUser(this.afAuth, this.afAuth.currentUser);
          sendEmailVerification(this.afAuth.currentUser);
        }
      )
    );
  }

  async signInWithEmailLink(){
    if(isSignInWithEmailLink(this.afAuth, window.location.href)){
      try{
        const urlParams = new URLSearchParams(window.location.search);
        await signInWithEmailLink(this.afAuth, urlParams.get('email'), window.location.href);
      }
      catch(err){
        throw err;
      }
    }
  }

  async sendPasswordEmail(email: string, action: string) {
    try{
      await this.fns.callFunction('resetPassword', {email: email, action: action});
    }
    catch(err){
      throw err;
    }
  }

  async checkCode(code: string){
    try{
      await checkActionCode(this.afAuth, code);
    }
    catch(err){
      throw err;
    }
  }

  resetPassword(credentials: IPasswordReset) {
    return from(confirmPasswordReset(this.afAuth, credentials.code, credentials.newPassword));
  }

  async setPassword(userId: string, password: string) {
    try{
      await this.fns.callFunction('setPassword', {userId: userId, password: password});
    }
    catch(err){
      throw err;
    }
  }

  get user() {
    return this.afAuth.currentUser;
  }

  async hasRole(role){
    try{
      const token = await this.user.getIdTokenResult();
      return token.claims[role] === true;
    }
    catch(err){
      console.warn(err);
    }
    return false;
  }

  async hasOneRole(roles: string[] | undefined){
    if(!Array.isArray(roles) || roles.length === 0){
      return true;
    }
    for(let i=0; i<roles.length; i++){
      if(await this.hasRole(roles[i])){
        return true;
      }
    }
    return false;
  }

  async unimpersonate(){
    if(!this.user){
      return;
    }
    try{
      const token = await this.user.getIdTokenResult();
      const response = await this.fns.callFunction('impersonate', {userId: token.claims.impersonatingUser});
      await signInWithCustomToken(this.afAuth, response);
    }
    catch(err){
      console.error(err);
    }
  }

  async impersonate(userId){
    try{
      const response = await this.fns.callFunction('impersonate', {userId: userId});
      await signInWithCustomToken(this.afAuth, response);
    }
    catch(err){
      console.error(err);
    }
  }

  async isImpersonating(){
    try{
      if(!this.user){
        return false;
      }
      const token = await this.user.getIdTokenResult();

      return Boolean(token.claims.isImpersonating);
    }
    catch(err){
      console.warn(err);
    }
    return false;
  }

}
