import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html'
})
export class HeadingComponent implements OnInit, OnChanges {
  currentRoute = '';

  @Input() title = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, private translateService: TranslateService) {
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      })
    ).subscribe((event) => {
      const path = this.router.url.split('?')[0];
      const paramtersLen = Object.keys(event.snapshot.params).length;
      const pathArr = path.split('/').slice(0, path.split('/').length - paramtersLen);
      this.currentRoute = pathArr[pathArr.length - 1];
    });
  }

  ngOnInit() {
    this.updateTitle();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.title){
      this.updateTitle();
    }
  }

  updateTitle(){
    this.titleService.setTitle(`${this.translateService.instant(this.title)} | ${environment.appName}`);
  }
}
