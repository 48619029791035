<div id="root">
  <div class="fixed-background">
    <div class="container document">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="card">
            <h1 class="mt-4" style="text-align: center;">Privacy Policy</h1>
            <p><i>Last updated: April 5th, 2021</i></p>
            <p>This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your
              information when you use the service and tells you about your privacy rights and how the law protects
              you.</p>
            <p>We use your personal data to provide and improve the service. By using the service, you agree to the
              collection and use of information in accordance with this Privacy Policy.</p>
            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>The following definitions shall have the same meaning regardless of whether they appear in
              singular or in plural.</p>
            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <strong>Account</strong> means a unique account created for you to access our Service or parts of
                  our Service.
              </li>
              <li>
                <strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;,
                  &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Laurent Chicoine Productions, 877 rue
                  charlevoix, Quebec, Canada.
              </li>
              <li>
                <strong>Cookies</strong> are small files that are placed on your computer, mobile device or any
                  other device by a website, containing the details of your browsing history on that website among its
                  many uses.
              </li>
              <li>
                <strong>Device</strong> means any device that can access the Service such as a computer, a
                  cellphone or a digital tablet.
              </li>
              <li>
                <strong>Personal Data</strong> is any information that relates to an identified or identifiable
                  individual.
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>Service Provider</strong> means any natural or legal person who processes the data on
                  behalf of the Company. It refers to third-party companies or individuals employed by the Company to
                  facilitate the Service, to provide the Service on behalf of the Company, to perform services related
                  to the Service or to assist the Company in analyzing how the Service is used.
              </li>
              <li>
                <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of
                  the Service or from the Service infrastructure itself (for example, the duration of a page visit).
              </li>
              <li>
                <strong>Website</strong> refers to BoothPilot, accessible from <a href="https://boothpilot.com"
                    rel="external nofollow noopener" target="_blank">https://boothpilot.com</a>
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the Service, or the company, or other
                  legal entity on behalf of which such individual is accessing or using the Service, as applicable.
              </li>
            </ul>
            <h2>Collecting and Using your Personal Data</h2>
            <h3>Types of Data Collected</h3>
            <h4>Personal Data</h4>
            <p>While using our Service, We may ask you to provide us with certain personally identifiable information
              that can be used to contact or identify you. Personally identifiable information may include, but is not
              limited to:</p>
            <ul>
              <li>
                Email address
              </li>
              <li>
                First name and last name
              </li>
              <li>
                Usage Data
              </li>
            </ul>
            <h4>Usage Data</h4>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data may include information such as your Device's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you visit, the time and date of your visit,
              the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p>When you access the Service by or through a mobile device, We may collect certain information
              automatically, including, but not limited to, the type of mobile device you use, your mobile device
              unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile
              Internet browser you use, unique device identifiers and other diagnostic data.</p>
            <p>We may also collect information that your browser sends whenever you visit our Service or when you
              access the Service by or through a mobile device.</p>
            <h4>Tracking Technologies and Cookies</h4>
            <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
              information. Tracking technologies used are beacons, tags, and scripts to collect and track information
              and to improve and analyze our Service. The technologies We use may include:</p>
            <ul>
              <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on your Device. You can
                instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if
                you do not accept Cookies, you may not be able to use some parts of our Service. Unless you have
                adjusted your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
              <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small
                electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who have visited those pages or opened an
                email and for other related website statistics (for example, recording the popularity of a certain
                section and verifying system and server integrity).</li>
            </ul>
            <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on your
              personal computer or mobile device when you go offline, while Session Cookies are deleted as soon as you
              close your web browser.</p>
            <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
            <ul>
              <li>
                <strong>Necessary / Essential Cookies</strong><br/>
                Type: Session Cookies<br/>
                Administered by: Us<br/>
                Purpose: These Cookies are essential to provide you with services available through the Website and
                  to enable you to use some of its features. They help to authenticate users and prevent fraudulent
                  use of user accounts. Without these Cookies, the services that you have asked for cannot be
                  provided, and We only use these Cookies to provide you with those services.
              </li>
              <li>
                <strong>Functionality Cookies</strong><br/>
                Type: Persistent Cookies<br/>
                Administered by: Us<br/>
                Purpose: These Cookies allow us to remember choices you make when you use the Website, such as
                  remembering your login details or language preference. The purpose of these Cookies is to provide
                  you with a more personal experience and to avoid you having to re-enter your preferences every time
                  you use the Website.
              </li>
            </ul>
            <h3>Use of your Personal Data</h3>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li>
                <strong>To provide and maintain our Service</strong>, including to monitor the usage of our
                  Service.
              </li>
              <li>
                <strong>To manage your Account:</strong> to manage your registration as a user of the Service. The
                  Personal Data you provide can give you access to different functionalities of the Service that are
                  available to you as a registered user.
              </li>
              <li>
                <strong>For the performance of a contract:</strong> the development, compliance and undertaking of
                  the purchase contract for the products, items or services you have purchased or of any other
                  contract with us through the Service.
              </li>
              <li>
                <strong>To contact you:</strong> To contact you by email, telephone calls, SMS, or other equivalent
                  forms of electronic communication, such as a mobile application's push notifications regarding
                  updates or informative communications related to the functionalities, products or contracted
                  services, including the security updates, when necessary or reasonable for their implementation.
              </li>
              <li>
                <strong>To provide you</strong> with news, special offers and general information about other
                  goods, services and events which we offer that are similar to those that you have already purchased
                  or enquired about unless you have opted not to receive such information.
              </li>
              <li>
                <strong>To manage your requests:</strong> To attend and manage your requests to us.
              </li>
              <li>
                <strong>For business transfers:</strong> We may use your information to evaluate or conduct a
                  merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar
                  proceeding, in which Personal Data held by Us about our Service users is among the assets
                  transferred.
              </li>
              <li>
                <strong>For other purposes</strong>: We may use your information for other purposes, such as data
                  analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and
                  to evaluate and improve our Service, products, services, marketing and your experience.
              </li>
            </ul>
            <p>We may share your personal information in the following situations:</p>
            <ul>
              <li><strong>With Service Providers:</strong> We may share your personal information with Service
                Providers to monitor and analyze the use of our Service, to contact you.</li>
              <li><strong>For business transfers:</strong> We may share or transfer your personal information in
                connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                acquisition of all or a portion of Our business to another company.</li>
              <li><strong>With affiliates:</strong> We may share your information with our affiliates, in which case
                we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company
                and any other subsidiaries, joint venture partners or other companies that we control or that are
                under common control with us.</li>
              <li><strong>With business partners:</strong> We may share your information with Our business partners to
                offer you certain products, services or promotions.</li>
              <li><strong>With other users:</strong> Administrators of your organization will have access to some of your personal data to manage your access to the Service.</li>
              <li><strong>With your consent</strong>: We may disclose your personal information for any other purpose
                with your consent.</li>
            </ul>
            <h3>Retention of your Personal Data</h3>
            <p>The Company will retain your Personal Data only for as long as is necessary for the purposes set out in
              this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain your data to comply with applicable
              laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
              retained for a shorter period of time, except when this data is used to strengthen the security or to
              improve the functionality of our Service, or we are legally obligated to retain this data for longer
              time periods.</p>
            <h3>Transfer of your Personal Data</h3>
            <p>Your information, including Personal Data, is processed at the Company's operating offices and in any
              other places where the parties involved in the processing are located. We use third-party vendors to provide
              the necessary technology required to run the Service, and such third-party vendors may collect and store your data on our behalf.
              It means that this information may be transferred to — and maintained on — computers located outside of your state, province, 
              country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
            <p>Your consent to this Privacy Policy followed by your submission of such information represents your
              agreement to that transfer.</p>
            <p>The Company will take all steps reasonably necessary to ensure that your data is treated securely and
              in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
              organization or a country unless there are adequate controls in place including the security of your
              data and other personal information.</p>
            <h3>Disclosure of your Personal Data</h3>
            <h4>Business Transactions</h4>
            <p>If the Company is involved in a merger, acquisition or asset sale, your Personal Data may be
              transferred. We will provide notice before your Personal Data is transferred and becomes subject to a
              different Privacy Policy.</p>
            <h4>Law enforcement</h4>
            <p>Under certain circumstances, the Company may be required to disclose your Personal Data if required to
              do so by law or in response to valid requests by public authorities (e.g. a court or a government
              agency).</p>
            <h4>Other legal requirements</h4>
            <p>The Company may disclose your Personal Data in the good faith belief that such action is necessary to:
            </p>
            <ul>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
              <li>Protect the personal safety of Users of the Service or the public</li>
              <li>Protect against legal liability</li>
            </ul>
            <h3>Security of your Personal Data</h3>
            <p>The security of your Personal Data is important to Us, but remember that no method of transmission over
              the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
            <h2>Children's Privacy</h2>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
              identifiable information from anyone under the age of 13. If you are a parent or guardian and you are
              aware that your child has provided us with Personal Data, please contact us. If We become aware that we
              have collected Personal Data from anyone under the age of 13 without verification of parental consent,
              we take steps to remove that information.</p>
            <h2>Third Party Services</h2>
            <p>Data collected on client's site is managed and controlled by the client (Operator). Our company is only the processor of the data collected.
              Operators are responsible to inform players, coaches or any other persons and if necessary, to obtain their consent to be recorded and to stream the live video on the internet.
            </p>
            <p>
              Our Service does not serve any advertisements neither on the Website or on content broadcasted to third party services.
            </p>
            <p>Our Service contains links and integrations to other websites that are not operated by us. If you click on a third
              party link, you will be directed to that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.</p>
            <p>Our Service includes optional integrations with:</p>
            <ul>
              <li>
                YouTube API Services which includes the following data: public links to videos, live streaming keys and access tokens. The data is stored securely with access control rules in place
                and is refreshed for validity and authorization every 7 days (at most). You can review Google Privacy Policy at <a href="http://www.google.com/policies/privacy" target="_blank">http://www.google.com/policies/privacy</a>.
                When using this integration, you agree with YouTube Terms of Service and Google Privacy Policy. You can revoke access to your YouTube account at any time, either by using the
                Revoke button in our application or via the Google security settings page at <a href="https://security.google.com/settings/security/permissions" target="_blank">https://security.google.com/settings/security/permissions</a>.
                When the access is revoked, all data associated with your YouTube account will be deleted from the application.
              </li>
              <li>
                Hudl. You can review Hudl Privacy Policy at <a href="https://www.hudl.com/privacy" target="_blank">https://www.hudl.com/privacy</a>
              </li>
            </ul>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of
              any third party sites or services.</p>
            <h2>Changes to this Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the
              new Privacy Policy on this page.</p>
            <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
              effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
              Policy are effective when they are posted on this page.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy or our privacy practices, you can contact us:</p>
            <ul>
              <li>By email: hostmaster&#64;boothpilot.com</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>