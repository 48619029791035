<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'menu.impersonate' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="impersonateForm">
            <div class="form-group">
                <label>{{ 'impersonate.user' | translate }}</label>
                <label class="w-100">
                    <ng-select appearance="outline" [searchable]="true" formControlName="user">
                        <ng-option *ngFor="let user of users" [value]="user.id">{{user.displayName}}
                        </ng-option>
                    </ng-select>
                </label>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary"
            (click)="modalRef.hide()">{{'modal.cancel' | translate }}</button>
    </div>
</ng-template>