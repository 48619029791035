import { Pipe, PipeTransform } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FirestoreService } from '../data/firestore.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'doc'
})
export class DocPipe implements PipeTransform {

  constructor(private db: FirestoreService) {}

  transform(value: DocumentReference): Observable<any> {
    if(!value || !value.path){
      return of('');
    }
    return this.db.getByPath(value.path);
  }

}
