import { Component, TemplateRef, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { FirestoreService } from '../../../data/firestore.service';

@Component({
  selector: 'app-impersonate-modal',
  templateUrl: './impersonate-modal.component.html',
  styles: []
})

export class ImpersonateModalComponent implements OnInit {
  @Input() data = {};
  impersonateForm: UntypedFormGroup;

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  users = [];
  initialized = false;

  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  constructor(private db: FirestoreService, private modalService: BsModalService) { }

  ngOnInit() {
    this.impersonateForm = new UntypedFormGroup({        
      user: new UntypedFormControl(null),
    });
    this.onChanges();
  }

  onChanges(){
    this.impersonateForm.get('user').valueChanges.subscribe(value => {
      if(value){
        this.formSubmitted.emit(value);
        this.modalRef.hide();
      }
    });
  }

  show() {
    if(!this.initialized){
      this.db.list('users').subscribe(
        data => {
          if (data) {
            this.users = data;
          }
        },
        error => {
          console.error(error);
        }
      );
      this.initialized = true;
    }

    this.impersonateForm.reset();
    this.modalRef = this.modalService.show(this.template, this.config);
  }

}
