import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {

  constructor(private titleService: Title, private translateService: TranslateService) { }

  ngOnInit() {
    document.body.classList.add('background');
    this.titleService.setTitle(`${this.translateService.instant('404')} | ${environment.appName}`);
  }

  ngOnDestroy() {
    document.body.classList.remove('background');
  }

}
