<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'user.add-new-user' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
            <div class="mt-4 form-group">
                <label>{{ 'user.displayName' | translate }}</label>
                <label class="w-100">
                    <input type="text" class="form-control" formControlName="displayName">
                    <div *ngIf="addUserForm.get('displayName')?.errors?.required && submitted" class="invalid-tooltip">{{ 'user.displayName' | translate }} {{'is-required' | translate}}</div>
                </label>
            </div>
            <div class="mt-4 form-group">
                <label>{{ 'user.email' | translate }}</label>
                <label class="w-100">
                    <input type="email" class="form-control" formControlName="email" email>
                    <div *ngIf="addUserForm.get('email')?.errors?.required && submitted" class="invalid-tooltip">{{ 'user.email' | translate }} {{'is-required' | translate}}</div>
                    <div *ngIf="!addUserForm.get('email')?.errors?.required && addUserForm.get('email')?.errors?.email && submitted" class="invalid-tooltip">{{ 'user.email' | translate }} {{'is-invalid' | translate}}</div>
                </label>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
            <button type="button" class="btn btn-outline-primary"
                (click)="modalRef.hide()" [disabled]="validSubmitted">{{'modal.cancel' | translate }}</button>
            <button type="submit" class="btn btn-primary float-right">
                {{ 'modal.add' | translate}}
            </button>
        </form>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="false" [name]="'onSubmit'" [showSpinner]="true"></ngx-spinner>
</ng-template>