import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

export function multiCheckboxValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: UntypedFormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireOneCheckboxToBeChecked: true,
      };
    }

    return null;
  };
}

export function emailsMatch(password: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.parent && control.parent.controls) {
      if (control.parent.controls[password].value !== control.value) {
        return { match: true };
      }
    }
    return null;
  };
}


export function timeRequired(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return  { required: true };
    }
    return null;
  };
}

export function dateLessThan(startKey: string, endKey: string): ValidatorFn {
  return (formGroup: UntypedFormGroup): { [key: string]: any} => {
    let start = formGroup.controls[startKey].value;
    let end = formGroup.controls[endKey].value;
    if(!(start instanceof Date) || !(end instanceof Date)){
      return null;
    }
    start.setMilliseconds(0);
    end.setMilliseconds(0);
    if (start >= end) {
      return {
        dateMoreThan: true
      };
    }
    return null;
  }
}

export function notSame(key1: string, key2: string): ValidatorFn {
  return (formGroup: UntypedFormGroup): { [key: string]: any} => {
    const value1 = formGroup.controls[key1].value;
    const value2 = formGroup.controls[key2].value;
    if (value1 != value2) {
      return {
        notSame: true
      };
    }
    return null;
  }
}