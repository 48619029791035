import { Pipe, PipeTransform, inject } from '@angular/core';
import { Storage, ref, getDownloadURL } from '@angular/fire/storage';
import { Observable, from } from 'rxjs';

@Pipe({
  name: 'storageUrl'
})
export class StorageUrlPipe implements PipeTransform {
  private readonly storage: Storage = inject(Storage);
  constructor() {}

  transform(path: string): Observable<any> {
    return from(getDownloadURL(ref(this.storage, path)));
  }

}
