import { Injectable, inject } from "@angular/core";
import { Functions, httpsCallableFromURL } from "@angular/fire/functions";
import { environment } from "src/environments/environment";

const maxAttempts = 5;

@Injectable({ providedIn: 'root' })
export class FunctionsService {
    constructor(private fns: Functions = inject(Functions)){}

    async callFunction(functionName: string, data: any): Promise<any>{
        let lastError: any = '';
        for(let i=0; i<maxAttempts; i++){
            try{
                const isLocalHost = window.location.origin.startsWith('http://localhost') || window.location.origin.startsWith('http://192.168.'); // Localhost does not have the required rewrites
                const fn = isLocalHost ? httpsCallableFromURL(this.fns, `https://${environment.functionRegion}-${environment.firebase.projectId}.cloudfunctions.net/${functionName}`) : httpsCallableFromURL(this.fns, `${window.location.origin}/api/${functionName}`);
                const response = await fn(data);
                
                return response.data;
            }
            catch(err: any){
                if(err.message && err.message.toLowerCase().includes('internal')){
                    lastError = err;
                    console.warn(`Error while calling ${functionName}. ${err}. Will be retried`);
                }
                else{
                    throw err;
                }
            }
        }

        throw lastError;
    }
}