import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByDate'
})
export class OrderByDatePipe implements PipeTransform {

  constructor() {}

  transform(array: Array<any>, opt?: any): Array<any> {
    return array.sort((a, b) =>{
      const diff = a[opt.attr].valueOf() - b[opt.attr].valueOf();
      const multiplier = opt.direction === 'desc' ? -1 : 1;
      return diff*multiplier;
    });
  }

}
