import { Component, TemplateRef, OnInit, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { timeRequired, dateLessThan } from './../../form-validations/custom.validators';
import { FirestoreService, QueryFilter } from '../../../data/firestore.service';
import { HudlDataService, HudlScheduleEntry, HudlCategories } from 'src/app/data/hudl.service';
import { VidswapTypes } from 'src/app/data/vidswap.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators'
import { PrivacyOptions } from '../../../data/recording.service';
import { AuthService } from 'src/app/shared/auth.service';
import { HudlEvent, HudlGroup, HudlMember, Team, TeamRecordingSetup, VideoTool } from 'src/app/data/types';

const EVENT_DATE_LOOKUP_DAYS = 14;
const EVENT_DATE_LOOKUP_WHEN_TRAINING_DAYS = 7;

@Component({
  selector: 'app-recording-modal',
  templateUrl: './recording-modal.component.html',
  styleUrls: ['./recording-modal.component.scss']
})
export class RecordingModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  @Input() data = {};
  recordingForm: UntypedFormGroup;

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

  modalRef: BsModalRef;
  minDate: Date | null;
  mytime = new Date();
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-right'
  };
  recordingSetups = [];
  teams = [];
  submitted: Boolean;
  hudlEvents: HudlScheduleEntry[];
  hudlAvailableShareList: any[];
  recordingSetupsLoading: Boolean = false;
  hudlEventsLoading: Boolean = false;
  hudlAvailableShareListLoading: Boolean = false;
  hudlCategories: any[] = HudlCategories;
  vidswapTypes: any[] = VidswapTypes;
  showHudlCategory: Boolean = false;
  hasYoutubeAccount: Boolean = false;
  hasHudlAccount: Boolean = false;
  hasVidswapAccount: Boolean = false;
  videoTool: string = '';
  videoToolVersion: string = '';
  team: any = {};
  teamName: String = '';
  oppTeamName: String = '';
  opponentTeams = [];
  opponentTeamsLoading: Boolean = false;
  privacyOptions = PrivacyOptions;
  isAdmin: boolean = false;
  user: any = {};

  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  constructor(private db: FirestoreService, private auth: AuthService, private modalService: BsModalService, private hudlDataService: HudlDataService) { }

  async ngOnInit() {
    this.recordingForm = new UntypedFormGroup({        
      team: new UntypedFormControl(null, [Validators.required]),
      homeTeam: new UntypedFormControl(null),
      hudlEvent: new UntypedFormControl(null),
      hudlSeason: new UntypedFormControl(null),
      eventName: new UntypedFormControl(null, [Validators.required]),
      hudlCategory: new UntypedFormControl(null),
      vidswapType: new UntypedFormControl(null),
      liveBroadcast: new UntypedFormControl(null),
      youtubeBroadcastKey: new UntypedFormControl(null),
      videoPrivacy: new UntypedFormControl(null),
      opponentTeam: new UntypedFormControl(null),
      recordingSetup: new UntypedFormControl(null, [Validators.required]),
      hudlShare: new UntypedFormControl(null),
      date: new UntypedFormControl(null, [Validators.required]),
      dateSpan: new UntypedFormGroup({
        start: new UntypedFormControl(null, [timeRequired()]),
        end: new UntypedFormControl(null, [timeRequired()])
      }, dateLessThan('start', 'end'))
    });
    
    this.isAdmin = await this.auth.hasRole('admin');
    this.user = await this.db.get('users', this.auth.user.uid).pipe(take(1)).toPromise();
    this.submitted = false;
    if(this.isAdmin){
      this.minDate = null;
    }
    else{
      this.minDate = new Date();
      this.minDate.setHours(this.minDate.getHours() - 1 * 24);
    }

    this.db.listUserData<Team>('teams', 'm2m').pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        if (data) {
          this.teams = data.sort((a,b) => {
            return a.name.localeCompare(b.name);
          });
          if(!this.recordingForm.get('team').value){
            //When no team selected, show Hudl fields if at least one team has Hudl
            this.hasHudlAccount = this.teams.some(team => team.hudlTeamId);
            this.hasVidswapAccount = this.teams.some(team => team.vidswapTeamId);
          }
        }
      },
      error => {
        console.error(error);
      }
    );
    this.onChanges();
  }

  ngOnDestroy(){
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onChanges() {
    let teamRecordingSetupSubscription, hudlEventsSubscription, videoToolSubscription, hudlMembersSubscription, hudlGroupsSubscription, opponentTeamsSubscription;
    this.recordingForm.get('team').valueChanges.subscribe(value => {
      this.recordingSetups = [];
      this.hudlEvents = [];
      this.hudlAvailableShareList = [];
      let hudlMembers = [];
      let hudlGroups = [];

      if(!value){
        return;
      }

      this.hudlEventsLoading = true;
      this.recordingSetupsLoading = true;
      this.hudlAvailableShareListLoading = true;
      this.opponentTeamsLoading = true;

      const team = this.teams.find(team =>{
        return team.path === value;
      })
      this.team = team;
      this.hasYoutubeAccount = Boolean(team.youtubeAccount);
      this.hasHudlAccount = Boolean(team.hudlTeamId);
      this.hasVidswapAccount = Boolean(team.vidswapTeamId);
      this.teamName = team.name;
      this.updateEventName();
      
      if(videoToolSubscription){
        videoToolSubscription.unsubscribe();
      }
      if(team.videoTool){
        videoToolSubscription = this.db.get<VideoTool>('videoTools', team.videoTool.v.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          data => {
            if (data && data.tool) {
              this.videoTool = data.tool;
            }
            if(data && data.version){
              this.videoToolVersion = data.version;
            }
          },
          error => {
            console.error(error);
          }
        );
      }
      else{
        this.videoTool = '';
      }

      if(teamRecordingSetupSubscription){
        teamRecordingSetupSubscription.unsubscribe();
      }
      teamRecordingSetupSubscription = this.db.get<TeamRecordingSetup>('teamRecordingSetups', team.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        data => {
          if (data && data.recordingSetups) {
            this.recordingSetups = data.recordingSetups;
          }
          this.recordingSetupsLoading = false;
        },
        error => {
          console.error(error);
        }
      );

      if(hudlEventsSubscription){
        hudlEventsSubscription.unsubscribe();
      }
      hudlEventsSubscription = this.db.list<HudlEvent>('hudlEvents', null, team.path).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        data => {
          if (data) {
            const hudlEvents = data.map(hudlEvent =>{
              return {
                time: hudlEvent.time,
                name: hudlEvent.name,
                id: hudlEvent.id,
                isPractice: hudlEvent.isPractice,
                seasonId: hudlEvent.seasonId,
                opponentId: hudlEvent.opponentId
              } as HudlScheduleEntry;
            });
            this.hudlEvents = hudlEvents.sort((eventA, eventB) => eventA.time.valueOf() - eventB.time.valueOf());
          }
          this.hudlEventsLoading = false;
          this.selectClosestHudlEvent();
        },
        error => {
          console.error(error);
        }
      );

      if(hudlMembersSubscription){
        hudlMembersSubscription.unsubscribe();
      }
      hudlMembersSubscription = this.db.list<HudlMember>('hudlMembers', null, team.path).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        data => {
          if (data) {
            hudlMembers = data.sort((memberA, memberB) =>{
              const nameA = `${memberA.firstName} ${memberA.lastName}`
              const nameB = `${memberB.firstName}${memberB.lastName}`
              return nameA.localeCompare(nameB);
            }).map(member =>{
              return {
                displayValue: `${member.firstName} ${member.lastName}`,
                value: `member,${member.id}`
              }
            });

            if(this.user.hudlUserId){
              const currentHudlUser = hudlMembers.find(hudlShare => hudlShare.value === `member,${this.user.hudlUserId}`);
              if(currentHudlUser){
                this.recordingForm.get('hudlShare').patchValue([currentHudlUser.value]);
              }
            }
          }
          if(hudlGroups.length > 0){
            this.hudlAvailableShareList = hudlGroups.concat(hudlMembers);
          }
          this.hudlAvailableShareListLoading = false;
        },
        error => {
          console.error(error);
        }
      );

      if(hudlGroupsSubscription){
        hudlGroupsSubscription.unsubscribe();
      }
      hudlGroupsSubscription = this.db.list<HudlGroup>('hudlGroups', null, team.path).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        data => {
          if (data) {
            hudlGroups = data.sort((grA, grB) =>{
              return grA.name.localeCompare(grB.name);
            }).map(group =>{
              return {
                displayValue: `${group.name} (${group.totalCount})`,
                value: `group,${group.id}`
              }
            });
          }
          if(hudlMembers.length > 0){
            this.hudlAvailableShareList = hudlGroups.concat(hudlMembers);
            this.hudlAvailableShareListLoading = false;
          }
        },
        error => {
          console.error(error);
        }
      );

      if(opponentTeamsSubscription){
        opponentTeamsSubscription.unsubscribe();
      }
      const opponentTeamsFilter: QueryFilter[] = team.isLocation ? [] : [['sport', '==', team.sport], ['level', '==', team.level], ['gender', '==', team.gender]];
      opponentTeamsSubscription = this.db.list<Team>('teams', opponentTeamsFilter).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        teams => {
          if (teams) {
            const usedTeamsPaths = this.opponentTeams.filter(team => [this.recordingForm.get('opponentTeam').value?.path, this.recordingForm.get('homeTeam').value?.path].includes(team.path)).map(team => team.path);
            this.opponentTeams = usedTeamsPaths.concat(teams.filter(team => team.organization && team.name && !usedTeamsPaths.includes(team.path)).map(team =>{
              return {
                ...team,
                displayValue: `${team.organization.d} ${team.name}`
              }
            }));
            this.opponentTeamsLoading = false;
          }
        },
        error => {
          console.error(error);
        }
      );
    });

    this.recordingForm.get('hudlEvent').valueChanges.subscribe(value => {
      if(!value){
        this.recordingForm.get('date').patchValue(null);
        this.recordingForm.get('hudlSeason').patchValue(null);
        this.showHudlCategory = false;
        return;
      }
      const hudlEvent = this.hudlEvents.find(event => {
        return event.id === value;
      });
      this.recordingForm.get('hudlSeason').patchValue(hudlEvent.seasonId);

      if(hudlEvent.opponentId){
        const opponentTeam = this.opponentTeams.find(opponentTeam => {
          return opponentTeam.hudlTeamId == hudlEvent.opponentId;
        });
        if(opponentTeam){
          this.recordingForm.patchValue({opponentTeam: opponentTeam.path});
        }
        else{
          this.db.getFirst<Team>('teams', [['hudlTeamId', '==', hudlEvent.opponentId]]).pipe(take(1)).subscribe(
            data => {
              if(data && data.id){
                this.opponentTeams.push({
                  ...data,
                  displayValue: `${data.organization.d} ${data.name}`
                });
                this.recordingForm.patchValue({opponentTeam: data.path});
              }
            }
          );
        }
      }
      else{
        this.recordingForm.patchValue({opponentTeam: null});
      }
      if(!this.recordingForm.get('opponentTeam').value){
        this.oppTeamName = hudlEvent.name;
      }
      this.updateEventName();
      if(this.recordingForm.get('hudlCategory').value === null || hudlEvent.isPractice){
        this.recordingForm.get('hudlCategory').patchValue(5); //Always set practice as default
      }
      if(this.recordingForm.get('hudlCategory').value === 4){
        this.recordingForm.get('date').patchValue(hudlEvent.time);
      }
      this.showHudlCategory = hudlEvent.isPractice ? false : true;
    });

    this.recordingForm.get('hudlCategory').valueChanges.subscribe(value => {
      if(value === 4){
        const hudlEvent = this.hudlEvents.find(event => {
          return event.id === this.recordingForm.get('hudlEvent').value;
        });
        this.recordingForm.get('date').patchValue(hudlEvent.time);
      }
      this.updateEventName();
    });

    ['date', 'homeTeam', 'opponentTeam'].forEach(fieldName =>{
      this.recordingForm.get(fieldName).valueChanges.subscribe(value => {
        this.updateEventName();
      });
    })
    this.recordingForm.get('dateSpan').get('start').valueChanges.subscribe(value => {
      this.updateEventName();
    });

    this.recordingForm.get('liveBroadcast').valueChanges.subscribe(value => {
      if(value === true){
        this.recordingForm.get('videoPrivacy').patchValue('public');
      }
      else{
        this.recordingForm.get('videoPrivacy').patchValue('unlisted');
      }
    });
  }

  updateEventName() {
    const date = this.recordingForm.get('date').value as Date;
    const start = new Date(this.recordingForm.get('dateSpan').get('start').value);
    
    let dateStr = '';
    if(start && date){
      start.setFullYear(date.getFullYear());
      start.setMonth(date.getMonth());
      start.setDate(date.getDate());
      dateStr = `${start.getFullYear()}-${("0" + (start.getMonth() + 1)).slice(-2)}-${("0" + start.getDate()).slice(-2)}`;
      if(this.videoTool === 'hudl' && this.recordingForm.get('hudlCategory').value !== 4){
        dateStr += ` ${String(start.getHours()).padStart(2, '0')}:${String(start.getMinutes()).padStart(2, '0')}`;
      }
    }

    if(this.recordingForm.get('opponentTeam').value){
      const opponentTeam = this.opponentTeams.find(team => team.path == this.recordingForm.get('opponentTeam').value);
      this.oppTeamName = opponentTeam.organization.d;
    }
    if(this.recordingForm.get('homeTeam').value){
      const homeTeam = this.opponentTeams.find(team => team.path == this.recordingForm.get('homeTeam').value);
      this.teamName = homeTeam.organization.d;
    }

    let eventNameParts = [];
    if(this.videoTool === 'hudl'){
      if(this.recordingForm.get('hudlCategory').value === 4){
        eventNameParts.push(`${this.teamName} VS ${this.oppTeamName}`);
      }
      else if(this.recordingForm.get('hudlCategory').value === 6){
        eventNameParts.push(`${this.oppTeamName} VS `);
      }
    }
    else{
      if(this.recordingForm.get('liveBroadcast').value){
        eventNameParts.push(`${this.teamName} VS ${this.oppTeamName}`);
      }
      else{
        eventNameParts.push(`${this.teamName}`);
      }
    }
    if(dateStr){
      eventNameParts.push(dateStr);
    }
    const eventName = eventNameParts.join(' - ');
    this.recordingForm.get('eventName').patchValue(eventName);
  }

  selectClosestHudlEvent() {
    if(this.hudlEvents.length === 0){
      this.recordingForm.get('hudlEvent').patchValue(null);
      return;
    }
    let closestEvent: HudlScheduleEntry;
    for(var i=0; i<this.hudlEvents.length; i++){
      closestEvent = this.hudlEvents[i];
      const lookupDays = closestEvent.isPractice ? EVENT_DATE_LOOKUP_WHEN_TRAINING_DAYS : EVENT_DATE_LOOKUP_DAYS;
      if(closestEvent.time > new Date() || i+1<this.hudlEvents.length && this.hudlEvents[i+1].time.valueOf() - new Date().valueOf() > lookupDays*24*60*60*1000){
        break;
      }
    }
    this.recordingForm.get('hudlEvent').patchValue(closestEvent.id);
  }

  show() {
    //Reinitialize dynamic lists
    this.recordingSetups = [];
    this.hudlEvents = [];
    this.hudlAvailableShareList = [];
    //Resinitialize form
    this.recordingForm.reset();
    //Set default values
    if(this.teams.length === 1){
      this.recordingForm.get('team').patchValue(this.teams[0].path);
    }
    this.recordingForm.patchValue({videoPrivacy: 'unlisted'});
    this.submitted = false;

    this.modalRef = this.modalService.show(this.template, this.config);
  }

  async refreshHudlEvents(){
    this.hudlEventsLoading = true;
    await this.hudlDataService.refreshData(this.recordingForm.get('team').value.split('/').at(-1), ['schedule']);
    this.hudlEventsLoading = false;
  }

  async refreshHudlMembersAndGroups(){
    this.hudlAvailableShareListLoading = true;
    await this.hudlDataService.refreshData(this.recordingForm.get('team').value.split('/').at(-1), ['membersAndGroups']);
    this.hudlAvailableShareListLoading = false;
  }

  onSubmit(){
    this.submitted = true;
    if(this.recordingForm.valid){
      let data = this.recordingForm.value;
      ['start', 'end'].forEach(function(field){
        data[field] = data.dateSpan[field];
        data[field].setFullYear(data.date.getFullYear());
        data[field].setMonth(data.date.getMonth());
        data[field].setDate(data.date.getDate());
        data[field].setMilliseconds(0);
      });
      delete data.dateSpan;
      delete data.date;
      ['opponentTeam', 'homeTeam', 'team'].forEach(field =>{
        if(data[field]){
          data[field] = this.db.getDocumentReference(data[field]);
        }
      });
      data.liveBroadcast = Boolean(data.liveBroadcast && this.hasYoutubeAccount && (!this.showHudlCategory || [4,5].indexOf(data.hudlCategory) > -1));
      data.opponentTeam = data.liveBroadcast ? data.opponentTeam : null;
      data.homeTeam = data.liveBroadcast && this.team.isLocation ? data.homeTeam : null;
      this.formSubmitted.emit(data);
      this.modalRef.hide();
    }
  }

}
