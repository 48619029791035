import { Component, TemplateRef, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styles: []
})

export class AddUserModalComponent implements OnInit {
  @Input() data = {};
  addUserForm: FormGroup;

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-right'
  };
  submitted = false;
  validSubmitted = false;

  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  constructor(private modalService: BsModalService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.addUserForm = new FormGroup({        
      displayName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email])
    });
  }

  show() {
    this.spinner.hide('onSubmit');
    this.submitted = false;
    this.addUserForm.reset();
    this.modalRef = this.modalService.show(this.template, this.config);
  }

  hide() {
    this.modalRef.hide();
  }

  onSubmit(){
    this.submitted = true;
    if(this.addUserForm.valid){
      this.spinner.show('onSubmit');
      this.validSubmitted = true;
      let data = this.addUserForm.value;
      this.formSubmitted.emit(data);
    }
  }
}
