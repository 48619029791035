import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'dateToMinutes'
})
export class DateToMinutesFormatPipe implements PipeTransform {
    transform(date: Date): number {
        if(!date){
            return 0;
        }
        
        return date.valueOf() / 1000 / 60;
    }
}