export const RECORDING_STATES = ["submitted", "scheduled", "recording", "processing", "completed", "cancelled", "error"] as const;

export const PrivacyOptions = [
  {value: 'public', displayValue: 'Public'},
  {value: 'unlisted', displayValue: 'Unlisted'},
  {value: 'private', displayValue: 'Private'},
]

export const RECORDING_STATE_COLORS = {
  "submitted": "light",
  "scheduled": "info",
  "recording": "danger",
  "processing": "warning",
  "completed": "success",
  "cancelled": "light",
  "error": "dark"
}