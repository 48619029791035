<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;" href="javascript:;"  class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;" class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <div *ngIf="isSearchActive" class="search" (click)="searchAreaClick($event)">
      <input placeholder="Search..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
      <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
    </div>
  </div>


  <a class="navbar-logo" href="/">
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>
  </a>

  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">

      <div *ngIf="isDarkSwitchActive" class="d-none d-md-inline-block align-text-bottom mr-1">
        <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive" (change)="onDarkModeChange($event)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div>

      <div class="position-relative d-inline-block">
        <div class="btn-group" dropdown (onHidden)="readNotifications()">
          <button dropdownToggle type="button" id="notificationButton" class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span class="count" *ngIf="unreadNotifications > 0">{{ unreadNotifications }}</span>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" id="notificationDropdown">
            <ng-scrollbar>
              <div *ngFor="let notification of notifications | orderByDate: {attr: 'created', direction: 'desc'}; last as isLast">
                <a *ngIf="notification.link" [href]="notification.link">
                  <div class="d-flex flex-row" [ngClass]="{'mb-3': !isLast, 'pb-3': !isLast, 'border-bottom': !isLast}">
                    <span *ngIf="!notification.read" class="custom-icon-dot"></span>
                    <div class="pl-3" [ngStyle]="{'margin-left': notification.read ? '1em' : '0'}">
                      <p class="font-weight-medium mb-1">{{ notification.message }}</p>
                      <p class="text-muted mb-0 text-small">{{ notification.created | timeAgo }}</p>
                    </div>
                  </div>
                </a>
                <div *ngIf="!notification.link" class="d-flex flex-row" [ngClass]="{'mb-3': !isLast, 'pb-3': !isLast, 'border-bottom': !isLast}">
                  <span *ngIf="!notification.read" class="custom-icon-dot"></span>
                  <div class="pl-3" [ngStyle]="{'margin-left': notification.read ? '1em' : '0'}">
                    <p class="font-weight-medium mb-1">{{ notification.message }}</p>
                    <p class="text-muted mb-0 text-small">{{ notification.created | timeAgo }}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="notifications.length === 0" class="d-flex flex-row">
                <div class="pl-3" style="margin-left: 1em;">
                  <p class="font-weight-medium mb-1">{{ 'notifications.no-notification' | translate }}</p>
                </div>
              </div>
            </ng-scrollbar>
          </div>
        </div>
      </div>

      <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
        <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
          {{currentLanguage | uppercase}} <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item" href="javascript:;">{{item.label}}</a></li>
        </ul>
      </div>

    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{displayName}}</span>
        <span class="initials">&nbsp;{{initials}}</span>
        <!--<span class="profile-picture">
          <img alt="Profile Picture" src="/assets/img/profile-pic-l.jpg" />
        </span>-->
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <!-- <a class="dropdown-item c-pointer">Account</a>
        <a class="dropdown-item c-pointer">Features</a>
        <a class="dropdown-item c-pointer">History</a>
        <a class="dropdown-item c-pointer">Support</a>
        <li class="divider dropdown-divider"></li> -->
        <a class="dropdown-item c-pointer" *ngIf="canImpersonate" (click)="onImpersonate()">{{ 'menu.impersonate' | translate }}</a>
        <a class="dropdown-item c-pointer" *ngIf="isImpersonating" (click)="onUnimpersonate()">{{ 'menu.unimpersonate' | translate }}</a>
        <a class="dropdown-item c-pointer" (click)="onSignOut()">{{ 'menu.sign-out' | translate }}</a>
      </div>
    </div>
  </div>
</nav>
<app-impersonate-modal (formSubmitted)="onImpersonateSubmitted($event)" #impersonateModalRef></app-impersonate-modal>