import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { RecordingModalComponent } from './recording-modal/recording-modal.component';
import { ImpersonateModalComponent } from './impersonate-modal/impersonate-modal.component';
import { AddUserModalComponent } from './add-user-modal/add-user-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [
    RecordingModalComponent,
    ImpersonateModalComponent,
    AddUserModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    CollapseModule,
    FormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    AlertModule.forRoot(),
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  exports: [
    RecordingModalComponent,
    ImpersonateModalComponent,
    AddUserModalComponent,
  ]
})
export class ApplicationsContainersModule { }
