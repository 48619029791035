export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: Array<string>;
  hasRole?: boolean;
}

const data: IMenuItem[] = [
  {
    id: 'schedule',
    icon: 'iconsminds-calendar-4',
    label: 'menu.schedule',
    to: '',
    subs: [
      {
        icon: 'simple-icon-list',
        label: 'menu.list',
        to: '/app/schedule/list'
      },
      {
        icon: 'simple-icon-calendar',
        label: 'menu.calendar',
        to: '/app/schedule/calendar',
        roles: ['admin']
      }
    ]
  },
  {
    id: 'team',
    icon: 'iconsminds-conference',
    label: 'menu.team',
    to: '',
    subs: [
      {
        icon: 'iconsminds-network',
        label: 'menu.manage-teams',
        to: '/app/manage/teams'
      },
      {
        icon: 'iconsminds-hotel',
        label: 'menu.manage-my-organization',
        to: '/app/manage/organization/{organizationId}',
      },
      {
        icon: 'iconsminds-hotel',
        label: 'menu.manage-organizations',
        to: '/app/manage/organizations',
        roles: ['admin']
      },
      {
        icon: 'iconsminds-male-female',
        label: 'menu.manage-team-members',
        to: '/app/manage/users',
        roles: ['admin']
      }
      ,
      {
        icon: 'iconsminds-bar-chart-4',
        label: 'menu.manage-leagues',
        to: '/app/manage/leagues',
        roles: ['admin']
      }
    ]
  },
  {
    id: 'configuration',
    icon: 'iconsminds-wrench',
    label: 'menu.configuration',
    to: '',
    subs: [
      {
        icon: 'iconsminds-security-camera',
        label: 'menu.cameras',
        to: '/app/configuration/cameras',
        roles: ['admin']
      },
      {
        icon: 'iconsminds-photo',
        label: 'menu.recordingSetups',
        to: '/app/configuration/recordingSetups'
      },
      {
        icon: 'iconsminds-cpu',
        label: 'menu.recorders',
        to: '/app/configuration/recorders',
        roles: ['admin']
      },
      {
        icon: 'iconsminds-type-pass',
        label: 'menu.integrationAccounts',
        to: '/app/configuration/integrationAccounts'
      }
    ]
  }
];
export default data;
