import { Injectable, inject } from '@angular/core';
import { DocumentReference, Firestore, QueryDocumentSnapshot, collection, docSnapshots, collectionGroup, collectionSnapshots, doc, query, where, DocumentSnapshot, FieldPath, WhereFilterOp, serverTimestamp, setDoc, Timestamp, FieldValue, arrayUnion, updateDoc, arrayRemove, orderBy, QueryConstraint, limit, deleteDoc, Query } from '@angular/fire/firestore';
import { catchError, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth.service';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { Reference } from './types';

interface UserTeams {
    teams: Reference[];
}

export interface QueryFilter {
    0: string | FieldPath;
    1: WhereFilterOp;
    2: unknown;
}

@Injectable({ providedIn: 'root' })
export class FirestoreService {
    userTeams: Observable<DocumentReference[]>;

    constructor(private firestore: Firestore = inject(Firestore), private authService: AuthService) {
        this.userTeams = docSnapshots(doc(collection(this.firestore, 'userTeams'), this.authService.user.uid)).pipe(
            map(userTeamsChange => {
                const userTeams = userTeamsChange.data() as UserTeams;
                return userTeams.teams.map(team => { return team.v });
            })
        );
    }

    async update(collectionId: string, data, parentRefPath?: string) {
        try{
            const id = data.id;
            delete data.id;
            delete data.ref;
            data.updated = serverTimestamp();
            data.updatedBy = {
                v: this.getDocumentReference(`users/${this.authService.user.uid}`),
                d: this.authService.user.displayName
            };
            data = await this.replaceReferences(data);
            let docRef = doc(collection(this.firestore, collectionId), id);
            if (parentRefPath) {
                const parentRef = doc(this.firestore, parentRefPath);
                docRef = doc(collection(parentRef, collectionId), id);
            }
            await setDoc(docRef, data, {merge: true});
        }
        catch(err){
            throw err;
        }
    }

    async upsert(collectionId: string, data, parentRefPath?: string) {
        data.id = data.id ? data.id : this.getNewDocId();
        if (!data.created) {
            data.created = serverTimestamp();
            data.createdBy = {
                v: this.getDocumentReference(`users/${this.authService.user.uid}`),
                d: this.authService.user.displayName
            };
        }
        else{
            delete data.created;
            if(data.createdBy){
                delete data.createdBy;
            }
        }
        return this.update(collectionId, data, parentRefPath);
    }

    async upsertMultipleDocuments(collectionId: string, dataArr: Array<any>) {
        if (dataArr.length === 0) {
            return;
        }

        try{
            await Promise.all(dataArr.map(async (data) => this.upsert(collectionId, data)));
        }
        catch(err){
            throw err;
        }
    }

    get<T>(collection: string, id: string) {
        return this.getByPath<T>(`/${collection}/${id}`);
    }

    getUserData(collection: string) {
        return this.getByPath(`/user${this.capitalize(collection)}/${this.authService.user.uid}`).pipe(
            map(data => {
                return data[collection].map(row => { return { ...row, path: row.v.path } });
            })
        );
    }

    getUserTeamsData(collection: string) {
        return this.userTeams.pipe(
            mergeMap(userTeams => {
                const observables = userTeams.map(team => {
                    return this.getByPath(`/team${this.capitalize(collection)}/${team.id}`).pipe(
                        map(teamItemsChange => {
                            return teamItemsChange[collection].map(row => { return { ...row, path: row.v.path } });
                        })
                    )
                })
                return combineLatest(observables).pipe(
                    map(teamItems => {
                        const flatTeamItems = this.flatten(teamItems) as Reference[];
                        return flatTeamItems.filter((item, index, self) => {
                            const findIndex = self.findIndex(selfItem => {
                                return selfItem.v.id === item.v.id;
                            })
                            return findIndex === index;
                        });
                    })
                )
            }),
            takeUntil(this.authService.initSignOut)
        )
    }

    getByPath<T>(path: string){
        return docSnapshots(doc(this.firestore, path)).pipe(
            map(change => {
                if (!change.exists()) {
                    return null;
                }
                const data = change.data();
                return this.getDocument<T>(change.id, data, change.ref);
            }),
            takeUntil(this.authService.initSignOut),
            catchError(err => {
                console.error(err);
                return of(null);
            })
        )
    }

    getFirst<T>(collectionId: string, queryAttributes?: QueryFilter[]) {
        const collectionRef = collection(this.firestore, collectionId);

        if(!queryAttributes){
            queryAttributes = [];
        }
        const queryConstraints: QueryConstraint[] = queryAttributes.map(queryAttribute => {
            return where(queryAttribute[0], queryAttribute[1], queryAttribute[2]);
        });
        queryConstraints.push(orderBy('created'));
        queryConstraints.push(limit(1));

        const collectionQuery = query(collectionRef, ...queryConstraints);

        return collectionSnapshots(collectionQuery).pipe(
            map(changes => {
                if (changes.length == 0) {
                    return null;
                }
                const data = changes[0].data();
                return this.getDocument<T>(changes[0].id, data, changes[0].ref);
            }),
            takeUntil(this.authService.initSignOut),
            catchError(err => {
                console.error(err);
                return of(null);
            })
        )
    }

    getNewDocId() {
        return doc(collection(this.firestore, 'id')).id;
    }

    //returns a list based on the teams of the user
    listUserTeamsData<T>(collectionId: string, type: 'm2m' | 'm2o', queryAttributes?: QueryFilter[]) {
        if (!queryAttributes) {
            queryAttributes = [];
        }
        const hasTeamFilter = queryAttributes.some(queryAttribute => queryAttribute[0] === 'team.v');
        let initialized = false;
        if (type === 'm2o') {
            return this.userTeams.pipe(
                mergeMap(userTeams => {
                    //There is a bug for user with more than 10 teams
                    if(!hasTeamFilter){
                        queryAttributes.splice(0, initialized ? 1 : 0, ['team.v', 'in', userTeams]);
                    }
                    initialized = true;
                    return this.list<T>(collectionId, queryAttributes);
                }),
                takeUntil(this.authService.initSignOut)
            );
        }
        return this.userTeams.pipe(
            mergeMap(userTeams => {
                const observables = userTeams.map(team => {
                    return docSnapshots(doc(collection(this.firestore, `team${this.capitalize(collectionId)}`), team.id)).pipe(
                        map(teamItemsChange => {
                            if(!teamItemsChange.exists()){
                                return [] as Reference[];
                            }
                            const teamItems = teamItemsChange.data() as any;
                            return teamItems[Object.keys(teamItems)[0]] as Reference[]; //teamData only has a single field
                        })
                    )
                })
                return combineLatest(observables).pipe(
                    mergeMap(teamItems => {
                        let flatTeamItems = this.flatten(teamItems) as Reference[];
                        flatTeamItems = flatTeamItems.filter((item, index, self) => {
                            const findIndex = self.findIndex(selfItem => {
                                return selfItem.v.id === item.v.id;
                            })
                            return findIndex === index;
                        });
                        const documentObservables = flatTeamItems.map(teamItemRef => {
                            return this.getByPath<T>(teamItemRef.v.path);
                        });
                        return combineLatest(documentObservables).pipe(
                            map(document => {
                                return document;
                            })
                        );
                    })
                )
            }),
            takeUntil(this.authService.initSignOut)
        )
    }

    listUserData<T>(collectionId: string, type: 'm2m' | 'm2o') {
        if (type === 'm2o') {
            return this.list<T>(collectionId, [['user.v', '==', doc(collection(this.firestore,'users'), this.authService.user.uid)]]);
        }
        return docSnapshots(doc(collection(this.firestore, `user${this.capitalize(collectionId)}`), this.authService.user.uid)).pipe(
            mergeMap(userItemsChange => {
                const userItems = userItemsChange.data() as any;
                const userItemsArray = userItems[collectionId] as Reference[];
                const observables = userItemsArray.map(userItems => {
                    return this.getByPath<T>(userItems.v.path);
                })
                return combineLatest(observables).pipe(
                    map(document => {
                        return document;
                    }),
                    takeUntil(this.authService.initSignOut)
                );
            }),
            takeUntil(this.authService.initSignOut)
        )
    }

    list<T>(collectionId: string, queryAttributes?: QueryFilter[], parentRefPath?: string, useCollectionGroup?: Boolean): Observable<T[]>{
        const queryConstraints: QueryConstraint[] = (queryAttributes || []).map(queryAttribute => {
            return where(queryAttribute[0], queryAttribute[1], queryAttribute[2]);
        });

        let collectionRef: Query;
        if (useCollectionGroup) {
            collectionRef = collectionGroup(this.firestore, collectionId);
        }
        else if (parentRefPath) {
            collectionRef = collection(doc(this.firestore, parentRefPath), collectionId);
        }
        else {
            collectionRef = collection(this.firestore, collectionId);
        }

        const collectionQuery = query(collectionRef, ...queryConstraints);

        return collectionSnapshots(collectionQuery).pipe(
            map(changes => {
                return changes.map(change => {
                    const data = change.data();
                    return this.getDocument<T>(change.id, data, change.ref);
                });
            }),
            takeUntil(this.authService.initSignOut),
            catchError(err => {
                console.error(err);
                return of([]);
            })
        );
    }

    async deleteDocument(collectionId: string, data) {
        try{
            const docRef = doc(collection(this.firestore, collectionId), data.id);

            await deleteDoc(docRef);
        }
        catch(err){
            throw err;
        }
    }

    deleteMultipleDocuments(collection: string, dataArr: Array<any>) {
        let s = this;
        return new Promise<void>((resolve, reject) => {
            let deleteCount = 0;
            dataArr.forEach(function (data) {
                s.deleteDocument(collection, data).then(res => {
                    deleteCount++
                    if (deleteCount >= dataArr.length)
                        resolve();
                }, err => reject(err));
            });
        });
    }

    getDocumentReference(path: string): DocumentReference {
        return doc(this.firestore, path);
    }

    private getDocument<T>(id: string, data: any, ref: DocumentReference): T{
        let document = {
            id: id,
            path: ref.path,
            ...data,
            ref: ref
        };
        this.convertToDate(document);

        return document;
    }

    private convertToDate(document: any){
        Object.keys(document).forEach((field) => {
            if (document[field] && typeof document[field].toDate === 'function') {
                document[field] = document[field].toDate()
            }

            if (Array.isArray(document[field])){
                document[field].forEach(item => {
                    if (item && typeof item.toDate === 'function') {
                        item = item.toDate();
                    }
                    else if(typeof item === 'object'){
                        this.convertToDate(item);
                    }
                });
            }
        });

        return document
    }

    private flatten(arr, result = []) {
        for (let i = 0, length = arr.length; i < length; i++) {
            const value = arr[i];
            if (Array.isArray(value)) {
                this.flatten(value, result);
            } else {
                result.push(value);
            }
        }
        return result;
    };

    private getRelCollection(one: string, many: string) {
        return `${this.singular(one)}${this.capitalize(many)}`;
    }

    private singular(str: string) {
        return str.replace(/s$/, '');
    }

    private capitalize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    private async replaceReferences(data: any){
        if(data && data.path && data.firestore){
            data = await this.getReferenceWithDisplay(data);
        }
        else if(Array.isArray(data)){
            data = await Promise.all(data.map(item => {
                if(item && item.path && item.firestore){
                    return item; // Do not convert array of Document References
                }

                return this.replaceReferences(item);
            }));
        }
        else if(data && typeof data === 'object' && data.constructor.name === 'Object' && !(data.v && data.d)){
            await Promise.all(Object.keys(data).map(async (field) => {
                data[field] = await this.replaceReferences(data[field]);
            }));
        }

        return data;
    }

    private async getReferenceWithDisplay(docRef: DocumentReference){
        const refData = await firstValueFrom(this.getByPath<any>(docRef.path));

        return {
            v: docRef,
            d: refData.displayValue || refData.displayName || refData.name || '',
        };
    }
}