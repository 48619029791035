import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsComponent } from './views.component';
import { ViewRoutingModule } from './views.routing';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '@angular/fire/auth';
import { AuthGuardModule } from '@angular/fire/auth-guard';

@NgModule({
  declarations: [ViewsComponent],
  imports: [
    CommonModule,
    ViewRoutingModule,
    SharedModule,
    AuthModule,
    AuthGuardModule
  ]
})
export class ViewsModule { }
