import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../views/error/error.component';
import { PrivacyComponent } from '../views/privacy/privacy.component';
import { TOSComponent } from '../views/tos/tos.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DocPipe } from './doc.pipe';
import { DateToMinutesFormatPipe } from './dateFormat.pipe';
import { OrderByDatePipe } from './orderByDate.pipe';
import { StorageUrlPipe } from './storageUrl.pipe';
import { TimeAgoPipe } from './time-ago.pipe';

@NgModule({
  declarations: [ErrorComponent, PrivacyComponent, TOSComponent, DocPipe, DateToMinutesFormatPipe, OrderByDatePipe, StorageUrlPipe, TimeAgoPipe],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    NgScrollbarModule,
  ],
  exports: [
    NgScrollbarModule,
    RouterModule,
    ErrorComponent,
    PrivacyComponent,
    TOSComponent,
    TranslateModule,
    CommonModule,
    DocPipe,
    DateToMinutesFormatPipe,
    OrderByDatePipe,
    StorageUrlPipe,
    TimeAgoPipe
  ]
})
export class SharedModule { }
