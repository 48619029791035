<div id="root">
  <div class="fixed-background">
    <div class="container document">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="card">
            <h1 class="mt-4" style="text-align: center;">Terms of Service</h1>
            <p><i>Last updated: April 5th, 2021</i></p>
            <p>Please read these terms of service carefully before using our Service.</p>
            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>The following definitions shall have the same meaning regardless of whether they appear in
              singular or in plural.</p>
            <h3>Definitions</h3>
            <p>For the purposes of these Terms of Service:</p>
            <ul>
              <li>
                <strong>Account</strong> means a unique account created for you to access our Service or parts of
                  our Service.
              </li>
              <li>
                <strong>Company</strong> (referred to as either &quot;the company&quot;, &quot;We&quot;,
                  &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Laurent Chicoine Productions, 877 rue
                  charlevoix, Quebec, Canada.
              </li>
              <li>
                <strong>Device</strong> means any device that can access the Service such as a computer, a
                  cellphone or a digital tablet.
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>Website</strong> refers to BoothPilot, accessible from <a href="https://boothpilot.com"
                    rel="external nofollow noopener" target="_blank">https://boothpilot.com</a>
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the Service, or the company, or other
                  legal entity on behalf of which such individual is accessing or using the Service, as applicable.
              </li>
            </ul>
            <h2>Acknowledgment</h2>
            <p>These are the Terms of Service governing the use of this Service and the agreement that operates
              between you and the company. These Terms of Service set out the rights and obligations of all users
              regarding the use of the Service.</p>
            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these
              Terms of Service. These Terms of Service apply to all visitors, users and others who access or use
              the Service.</p>
            <p>By accessing or using the Service you agree to be bound by these Terms of Service. If you disagree
              with any part of these Terms of Service then you may not access the Service.</p>
            <p>You represent that you are over the age of 18. The company does not permit those under 18 to use the
              Service.</p>
            <p>Your access to and use of the Service is also conditioned on your acceptance of and compliance with the
              Privacy Policy of the company. Our Privacy Policy describes our policies and procedures on the collection,
              use and disclosure of your personal information when you use the Application or the Website and tells you
              about your privacy rights and how the law protects you. Please read our Privacy Policy carefully before
              using our Service.</p>
            <h2>Third Party Services</h2>
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by
              the company.</p>
            <p>The company has no control over, and assumes no responsibility for, the content, privacy policies, or
              practices of any third party web sites or services. you further acknowledge and agree that the company
              shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be
              caused by or in connection with the use of or reliance on any such content, goods or services available on
              or through any such web sites or services.</p>
            <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites
              or services that you visit.</p>
            <p>Our Service includes optional integrations with:</p>
            <ul>
              <li>
                YouTube API Services which can be used to live broadcast an event. By using this integration, you are agreeing to be bound by the YouTube Terms of Service. You can review YouTube Terms of Service at <a href="https://www.youtube.com/t/terms" target="_blank">https://www.youtube.com/t/terms</a>.
                When using this integration, you agree with YouTube Terms of Service and Google Privacy Policy.
              </li>
              <li>
                Hudl which can be used to automatically upload the captured events to your Hudl team. You can review Hudl Terms and Conditions at <a href="https://www.hudl.com/terms" target="_blank">https://www.hudl.com/terms</a>
              </li>
            </ul>
            <h2>Termination</h2>
            <p>We may terminate or suspend your access immediately, without prior notice or liability, for any reason
              whatsoever, including without limitation if you breach these Terms of Service.</p>
            <p>Upon termination, your right to use the Service will cease immediately.</p>
            <h2>Limitation of Liability</h2>
            <p>Notwithstanding any damages that you might incur, the entire liability of the company and any of its
              suppliers under any provision of this Terms and your exclusive remedy for all of the foregoing shall be
              limited to the amount actually paid by you through the Service or 100 CAD if you haven't purchased
              anything through the Service.</p>
            <p>To the maximum extent permitted by applicable law, in no event shall the company or its suppliers be
              liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not
              limited to, damages for loss of profits, loss of data or other information, for business interruption, for
              personal injury, loss of privacy arising out of or in any way related to the use of or inability to use
              the Service, third-party software and/or third-party hardware used with the Service, or otherwise in
              connection with any provision of this Terms), even if the company or any supplier has been advised of the
              possibility of such damages and even if the remedy fails of its essential purpose.</p>
            <p>Some jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or
              consequential damages, which means that some of the above limitations may not apply. In these jurisdictions, each
              party's liability will be limited to the greatest extent permitted by law.</p>
            <h2>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h2>
            <p>The Service is provided to you &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and
              defects without warranty of any kind. To the maximum extent permitted under applicable law, the company,
              on its own behalf and on behalf of its Affiliates and its and their respective licensors and service
              providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with
              respect to the Service, including all implied warranties of merchantability, fitness for a particular
              purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of
              performance, usage or trade practice. Without limitation to the foregoing, the company provides no
              warranty or undertaking, and makes no representation of any kind that the Service will meet your
              requirements, achieve any intended results, be compatible or work with any other software, applications,
              systems or services, operate without interruption, meet any performance or reliability standards or be
              error free or that any errors or defects can or will be corrected.</p>
            <p>Without limiting the foregoing, neither the company nor any of the company's provider makes any
              representation or warranty of any kind, express or implied: (i) as to the operation or availability of the
              Service, or the information, content, and materials or products included thereon; (ii) that the Service
              will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information
              or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails
              sent from or on behalf of the company are free of viruses, scripts, trojan horses, worms, malware,
              timebombs or other harmful components.</p>
            <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
              statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to
              you. But in such a case the exclusions and limitations set forth in this section shall be applied to the
              greatest extent enforceable under applicable law.</p>
            <h2>Governing Law</h2>
            <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and your use of
              the Service. your use of the Application may also be subject to other local, state, national, or
              international laws.</p>
            <h2>Disputes Resolution</h2>
            <p>If you have any concern or dispute about the Service, you agree to first try to resolve the dispute
              informally by contacting the company.</p>
            <h2>Severability and Waiver</h2>
            <h3>Severability</h3>
            <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed
              and interpreted to accomplish the objectives of such provision to the greatest extent possible under
              applicable law and the remaining provisions will continue in full force and effect.</p>
            <h3>Waiver</h3>
            <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation
              under this Terms shall not effect a party's ability to exercise such right or require such performance at
              any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
            <h2>Translation Interpretation</h2>
            <p>These Terms of Service may have been translated if We have made them available to you on our Service.
              you agree that the original English text shall prevail in the case of a dispute.</p>
            <h2>Changes to These Terms of Service</h2>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision
              is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms
              taking effect. What constitutes a material change will be determined at our sole discretion.</p>
            <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound
              by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the
              website and the Service.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about these Terms of Service, you can contact us:</p>
            <ul>
              <li>By email: hostmaster&#64;boothpilot.com</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>