<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{'schedule.add-new-title' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="recordingForm" (ngSubmit)="onSubmit()">
            <div class="mt-4 form-group">
                <label>{{ 'schedule.team' | translate }}</label>
                <label class="w-100">
                    <ng-select appearance="outline" [searchable]="isAdmin" formControlName="team">
                        <ng-option *ngFor="let team of teams" [value]="team.path">{{team.name}}
                        </ng-option>
                    </ng-select>
                    <div *ngIf="recordingForm.get('team').errors?.required && submitted" class="invalid-tooltip">
                        {{ 'schedule.team' | translate }} {{ 'is-required' | translate }}</div>
                </label>
            </div>
            <div *ngIf="hasHudlAccount && videoTool === 'hudl'" class="mt-4 form-group">
                <label>{{ 'schedule.hudlEvent' | translate }}</label>&nbsp;<button type="button" *ngIf="recordingForm.get('team').value !== null" (click)="refreshHudlEvents()" class="btn no-padding iconsminds-repeat-2"></button>
                <label class="w-100">
                    <ng-select appearance="outline" [items]="hudlEvents" bindLabel="name" bindValue="id" [searchable]="false" [loading]="hudlEventsLoading" formControlName="hudlEvent">
                        <ng-template ng-option-tmp let-item="item">
                            <div>{{item.name}}</div>
                            <small>{{item.time | date: 'yyyy-MM-dd'}}</small>
                        </ng-template>
                    </ng-select>
                    <alert type="warning" *ngIf="!recordingForm.get('hudlEvent').value && videoToolVersion === 'classic'">
                        <strong>Video will not be uploaded to Hudl if left empty</strong>
                    </alert>
                    <div *ngIf="recordingForm.get('hudlEvent').errors?.required && submitted" class="invalid-tooltip">
                        {{ 'schedule.hudlEvent' | translate }} {{ 'is-required' | translate }}</div>
                </label>
            </div>
            <div class="mt-4 form-group" *ngIf="showHudlCategory">
                <label>{{ 'schedule.hudlCategory' | translate }}</label>
                <label class="w-100">
                    <div class="form-check" *ngFor="let hudlCategory of hudlCategories">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="hudlCategory" [value]="hudlCategory.value">
                            {{ hudlCategory.displayValue }}
                        </label>
                    </div>
                </label>
            </div>
            <div class="mt-4 form-group" *ngIf="hasVidswapAccount && videoTool === 'vidswap'">
                <label>{{ 'schedule.vidswapType' | translate }}</label>
                <label class="w-100">
                    <div class="form-check" *ngFor="let vidswapType of vidswapTypes">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" formControlName="vidswapType" [value]="vidswapType.value">
                            {{ vidswapType.displayValue }}
                        </label>
                    </div>
                </label>
            </div>
            <ng-template [ngIf]="hasYoutubeAccount">
                <ng-template [ngIf]="(!showHudlCategory || [4,5].indexOf(recordingForm.get('hudlCategory').value) > -1)">
                    <div class="mt-4 form-group">
                        <div class="custom-control custom-checkbox mb-4">
                            <input type="checkbox" class="custom-control-input" formControlName="liveBroadcast" id="liveBroadcast">
                            <label class="custom-control-label" for="liveBroadcast">{{ 'schedule.liveBroadcast' | translate }}</label>
                        </div>
                    </div>
                    <div class="mt-4 form-group" *ngIf="recordingForm.get('liveBroadcast').value || videoTool === 'youtube'">
                        <label>{{ 'schedule.videoPrivacy' | translate }}</label>
                        <label class="w-100">
                            <div class="form-check" *ngFor="let privacyOption of privacyOptions">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" formControlName="videoPrivacy" [value]="privacyOption.value">
                                    {{ privacyOption.displayValue }}
                                </label>
                            </div>
                        </label>
                    </div>
                    <ng-template [ngIf]="recordingForm.get('liveBroadcast').value">
                        <div class="mt-4 form-group" *ngIf="recordingForm.get('liveBroadcast').value">
                            <label>{{ 'schedule.youtubeBroadcastKey' | translate }}</label>
                            <label class="w-100">
                                <input class="form-control" formControlName="youtubeBroadcastKey" />
                            </label>
                        </div>
                        <div class="mt-4 form-group" *ngIf="team.isLocation">
                            <label>{{ 'schedule.homeTeam' | translate }}</label>
                            <label class="w-100">
                                <ng-select appearance="outline" [items]="opponentTeams" [searchable]="true" bindLabel="displayValue" bindValue="path" formControlName="homeTeam" [loading]="opponentTeamsLoading">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div>{{ item.name }}</div>
                                        <small>{{ item.organization.d }}</small>
                                    </ng-template>
                                </ng-select>
                            </label>
                        </div>
                        <div class="mt-4 form-group">
                            <label>{{ 'schedule.opponentTeam' | translate }}</label>
                            <label class="w-100">
                                <ng-select appearance="outline" [items]="opponentTeams" [searchable]="true" bindLabel="displayValue" bindValue="path" formControlName="opponentTeam" [loading]="opponentTeamsLoading">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div>{{ item.name }}</div>
                                        <small>{{ item.organization.d }}</small>
                                    </ng-template>
                                </ng-select>
                            </label>
                        </div>
                    </ng-template>
                </ng-template>
            </ng-template>
            <div class="mt-4 form-group">
                <label>{{ 'schedule.date' | translate }}</label>
                <input type="text" placeholder="Datepicker" class="form-control" formControlName="date" bsDatepicker
                    [container]="'html'" [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'YYYY-MM-DD' }"
                    [minDate]="minDate">
                <div *ngIf="recordingForm.get('date')?.errors?.required && submitted" class="invalid-tooltip">
                    {{ 'schedule.date' | translate }} {{ 'is-required' | translate }}</div>
            </div>
            <div class="mt-4" formGroupName="dateSpan">
                <div class="row">
                    <div class="col-xs-6 form-group">
                        <label>{{ 'schedule.start-time' | translate }}</label>
                        <timepicker class="timepicker" formControlName="start" [hourStep]="1" [minuteStep]="10"
                            [showMeridian]="false" [mousewheel]="false" [showSpinners]="false"></timepicker>
                        <div *ngIf="recordingForm.get('dateSpan').get('start')?.errors?.required && submitted" class="invalid-tooltip">
                            {{ 'schedule.start-time' | translate }} {{ 'is-required' | translate }}</div>
                    </div>
                    <div class="col-xs-6 form-group">
                        <label>{{ 'schedule.end-time' | translate }}</label>
                        <timepicker class="timepicker" formControlName="end" [hourStep]="1" [minuteStep]="10"
                            [showMeridian]="false" [mousewheel]="false" [showSpinners]="false"></timepicker>
                        <div *ngIf="recordingForm.get('dateSpan').get('end')?.errors?.required && submitted" class="invalid-tooltip">
                            {{ 'schedule.end-time' | translate }} is required!</div>
                        <div *ngIf="recordingForm.get('dateSpan')?.errors?.dateMoreThan" class="invalid-tooltip">
                            {{ 'schedule.end-time' | translate }} must be after {{ 'schedule.start-time' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="mt-4 form-group">
                <label>{{ 'schedule.eventName' | translate }}</label>
                <label class="w-100">
                    <input class="form-control" formControlName="eventName" />
                    <div *ngIf="recordingForm.get('eventName').errors?.required && submitted" class="invalid-tooltip">
                        {{ 'schedule.eventName' | translate }} {{ 'is-required' | translate }}</div>
                </label>
            </div>
            <div class="mt-4 form-group">
                <label>{{ 'schedule.recordingSetup' | translate }}</label>
                <label class="w-100">
                    <ng-select appearance="outline" [items]="recordingSetups" [searchable]="true" [loading]="recordingSetupsLoading" bindValue="v" bindLabel="d" formControlName="recordingSetup">
                    </ng-select>
                    <div *ngIf="recordingForm.get('recordingSetup').errors?.required && submitted" class="invalid-tooltip">
                        {{ 'schedule.recordingSetup' | translate }} {{ 'is-required' | translate }}</div>
                </label>
            </div>
            <div *ngIf="hasHudlAccount" class="mt-4 form-group">
                <label>{{ 'schedule.hudlShare' | translate }}</label>&nbsp;<button type="button" *ngIf="recordingForm.get('team').value !== null" (click)="refreshHudlMembersAndGroups()" class="btn no-padding iconsminds-repeat-2"></button>
                <label class="w-100">
                    <ng-select [loading]="hudlAvailableShareListLoading" [items]="hudlAvailableShareList" [hideSelected]="true" [multiple]="true" bindLabel="displayValue" bindValue="value" [closeOnSelect]="false" formControlName="hudlShare">
                    </ng-select>
                </label>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <form [formGroup]="recordingForm" (ngSubmit)="onSubmit()">
            <button type="button" class="btn btn-outline-primary"
                (click)="modalRef.hide()">{{'modal.cancel' | translate }}</button>
            <button type="submit" class="btn btn-primary float-right">{{ 'modal.submit' | translate}}</button>
        </form>
    </div>
</ng-template>