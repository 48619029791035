import { Injectable } from '@angular/core';
import { FunctionsService } from '../shared/functions.service';

export interface HudlScheduleEntry {
  id: string;
  name: string;
  isPractice: boolean;
  time: Date;
  seasonId: string;
  opponentId: string;
}

interface HudlScheduleEntryResponse {
  id: string;
  name: string;
  isPractice: Boolean;
  time: string;
}

export interface HudlMember {
  id: string;
  firstName: string;
  lastName: string;
}

export interface HudlGroups {
  id: string;
  name: string;
  totalCount: number;
}

export interface HudlMembersAndGroups {
  members: HudlMember[];
  groups: HudlGroups[];
}

export interface HudlData {
  schedule: HudlScheduleEntry[];
  membersAndGroups: HudlMembersAndGroups;
}

export enum HudlCategory {
  Game = 4,
  Practice = 5,
  Scout = 6
}

export const HudlCategories = [
  {value: HudlCategory.Game, displayValue: 'Game'},
  {value: HudlCategory.Practice, displayValue: 'Practice'},
  {value: HudlCategory.Scout, displayValue: 'Scout'}
]

@Injectable({ providedIn: 'root' })
export class HudlDataService {
  constructor(private fns: FunctionsService) { }

  async refreshData(teamId: string, types: string[]) {
    try{
      await this.fns.callFunction('refreshHudlDataOnDemand', {teamId: teamId, types: types});
    }
    catch(err){
      throw(err);
    }
  }
}
