// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: 'BoothPilotDEV',
  production: false,
  baseUrl: 'http://localhost:4201',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: false,
  /*
  Color Options:
  'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss', 'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot', 'light.redruby', 'light.purplemonster'
  'dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss', 'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot', 'dark.redruby', 'dark.purplemonster'
  */
  defaultColor: 'light.redruby',
  isDarkSwitchActive: false,
  defaultDirection: 'ltr',
  themeRadius: 'rounded', /*flat or rounded also needs to update index.html body class which is out of angular scope*/
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  /*Firebase config can be retrieved from Firebase console inside the Project settings*/
  firebase: {
    apiKey: "AIzaSyB-RbJ86yLYkqiW2vbfMq6lNpNemc4RWnw",
    authDomain: "boothpilotdev.firebaseapp.com",
    projectId: "boothpilotdev",
    storageBucket: "boothpilotdev.appspot.com",
    messagingSenderId: "213475640034",
    appId: "1:213475640034:web:3898bd61bc858704c254bb"
  },
  functionRegion: 'us-central1',
};
